import React, { useState } from "react";

export default function AddToList() {
  const [values, setValues] = useState({
    restaurantName: "",
    restaurantLocation: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const [valid, setValid] = useState(false);

  const handleRestaurantName = (event) => {
    setValues({ ...values, restaurantName: event.target.value });
  };

  const handleRestaurantLocation = (event) => {
    setValues({ ...values, restaurantLocation: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(values.restaurantName && values.restaurantLocation) {
      setValid(true);
    } 
    setSubmitted(true);
  };


  return (
    <div>
      {submitted && valid ? 
      <div>
        <div>Thanks homie, your restaurant was submitted</div>
        {<ListPostReq restaurantName ={values.restaurantName} restaurantLocation={values.restaurantLocation}/>}
      </div> 
      : null}
      {submitted && !valid ? <div style={{color: "red"}}>Please enter name or place</div> : null}
      <h1>Suggest a spot!</h1>
      <form onSubmit={handleSubmit}>
        <input
          onChange={handleRestaurantName}
          value={values.restaurantName}
          id="restaurant-name"
          type="text"
          placeholder="Restaurant Name"
          name="restaurantName"
        />
        <div>
          <input
            onChange={handleRestaurantLocation}
            value={values.restaurantLocation}
            id="restaurant-location"
            type="text"
            placeholder="Restaurant Location"
            name="restaurantLocation"
          />
        </div>
        <button type="submit">Request to Add</button>
      </form>
      <p>Fill out the form to add your restaurant to the list!</p>
    </div>
  );
}

class ListPostReq extends React.Component {  

  componentDidMount() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: this.props.restaurantName, location: this.props.restaurantLocation})
      };
  
      fetch('/api/add-to-list', requestOptions)
      .then(response => response.json());
    }

  render() {
      return(
        <div>
          <h1>{this.props.restaurantName}</h1>
        </div>
          
      );
      
  }
}

export { ListPostReq }; 

