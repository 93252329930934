import React, { Component } from "react";

class SeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  // Get data
  componentDidMount() {
    fetch("/api/restaurant-list")
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
        });
      });
  }

  render() {
    // Loading
    if (this.state.items.length < 1) return <h1>Loading data</h1>;

    return (
      <div>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}
        >
          List of Restaurants and their location
        </h1>
        {this.state.items.map((item) => {
              return (
                <div>
                   {item.name} at {item.location}
                </div>
              );
            })}
      </div>
    );
  }
}

export default SeeList;
