import { Navbar, Nav, Container } from "react-bootstrap";

const Navigation = () => {
  return (
    <>
      <Navbar style={{position: "sticky"}}collapseOnSelect top="0" fixed="top" expand="sm" bg="dark" variant="dark">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link href="/"> Home </Nav.Link>
              <Nav.Link href="/restaurant-list"> Spot List </Nav.Link>
              <Nav.Link href="/restaurant-rec"> Suggest a Spot </Nav.Link>
              <Nav.Link href="/random-spot"> Random Spot </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
