import React from "react";
import {createRoot} from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./index.css";
import AddToList from "./pages/add-to-list";
import Home from "./pages/home";
import Navigation from "./pages/navigation";
import SeeList from "./pages/see-list";
import WhereToGo from "./pages/where-to-go";
import 'bootstrap/dist/css/bootstrap.min.css';



const root = createRoot(document.getElementById("root"));

root.render(
  <div className="App">
    <Navigation />

    <BrowserRouter>
   <Routes>
    <Route exact path="/" element={<Home/>} />
    <Route exact path="/restaurant-list" element={<SeeList/>} />
    <Route path="/restaurant-rec" element={<AddToList/>} />
    <Route path="/random-spot" element={<WhereToGo/>} />
  </Routes>
  </BrowserRouter>
  </div>  
);