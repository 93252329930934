import React from "react";

import "../index.css";

class WhereToGo extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        items: [],
        randomNumber: null,
      };
    }
  
    componentDidMount() {
      fetch("/api/restaurant-list")
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            items: json,
          });
        });
    }
  
    render() {
      if (this.state.items.length < 1) return <h1>Loading data</h1>;
  
      var rN = Math.floor(Math.random() * this.state.items.length);
  
      return (
        <div>
  
          <div
          
          style={{
            height: "300px",
            position: "relative",
          }}
  
          >
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "45vh",
              }}
            >
              You should go to {this.state.items[rN].name} in{" "}
              {this.state.items[rN].location}{" "}
            </h1>
          </div>
  
          <div
            style={{
              height: "200px",
              position: "relative",
            }}
          >
            <button
              style={{
                margin: "0",
                position: "absolute",
                top: "10%",
                left: "45%",
              }}
              onClick={() => {
                
                var randomNumber = Math.floor(
                  Math.random() * this.state.items.length
                );
    
                this.setState({ randomNumber: randomNumber });
              }}
              className="myButton"
            >
              Click me for a restaurant
            </button>
          </div>
        </div>
      );
    }
  }

  export default WhereToGo;